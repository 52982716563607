import React, { useState } from 'react';
import useUser from "utils/useUser";

const withUser = (Component, shouldRedirect=true) => {
  return (props) => {
    var config = {}
    const [ withTimeout, setWithTimeout ] = useState(false)
    
    if (shouldRedirect == true) {
      config['redirectTo'] = "/login?r=1"
    }

    const { user } = useUser(config);

    if(user) {
      return <Component user={user} {...props} />;
    } else {
      if (!user || user.isLoggedIn === false) {
        setTimeout(() => {
          setWithTimeout(true)
        }, 5000);
        return (
        <>
          <span className="loader"><span className="loader2">Loading...</span>
            {withTimeout == true && (            
              <><br/>Something went wrong. Please do force refresh your browser. You can do that by pressing ctrl+shift+r or cmd+shift+r using the keyboard, or by removing cache of the browser manually. If that doesn't help, then please try to re-login.</>
            )}
          </span>
        </>)
        return 
      } 
    }
    
  };
};

export default withUser