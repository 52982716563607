import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Container } from "@material-ui/core"
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { useEffect, useState } from "react";
import image from "assets/img/bg11.jpg";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/nextjs-material-kit/pages/loginPage.js";
import withUser from 'utils/pageWrappers/withUser'
import { withRouter } from 'next/router'

const useStyles = makeStyles(styles);

function Calculator(props) {

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="My Love - Fitness"
        rightLinks={<HeaderLinks {...props}/>}
        {...props}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "bottom center"
        }}
      >
        <div className={classes.container}>
          <Container justify="center">
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    We cannot find this page.
                  </CardHeader>
                  <CardBody align={'center'}>
                    <h3>We're sorry, but we cannot find this page.<br/><br/></h3>
                    <img width='100%' src='https://media3.giphy.com/media/14uQ3cOFteDaU/giphy.gif'></img>
                  </CardBody>
                </form>
              </Card>
          </Container>
        </div>
        <Footer whiteFont {...props}/>
      </div>
    </div>
  );
}

export default withRouter(withUser(Calculator, false))